<template>
  <div class="wrapper">
    <div class="banner" id="index">
      <img class="image" src="@/assets/banner.png" alt="banner"/>
    </div>
    <div class="center">
      <div class="data">
        <div class="title">智安康预警服务，为生命保驾护航</div>
        <div class="content">
          <div class="item">
            <div class="name">健康预警</div>
            <div class="value">192<span class="unit">次</span></div>
          </div>
          <div class="item">
            <div class="name">健康提示</div>
            <div class="value">240<span class="unit">次</span></div>
          </div>
          <div class="item">
            <div class="name">SOS急救用户</div>
            <div class="value">52<span class="unit">人</span></div>
          </div>
        </div>
        <img class="image" src="@/assets/platform.png" alt="platform"/>
      </div>
      <div class="service" id="service">
        <div class="title">我们的服务</div>
        <div class="content">
          <div class="left">
            <div class="monitor">
              <img class="image" src="@/assets/watch.jpg" alt="watch"/>
              <div class="right">
                <div class="top">
                  <div class="subtitle">健康监测</div>
                  <div>实时监测身体健康状态</div>
                </div>
                <div class="bottom">
                  <div class="line">
                    <div class="item">
                      <img class="icon" src="@/assets/icon/ic_heart_rate.jpg" alt="heart rate"/>
                      <div class="value">心率</div>
                    </div>
                    <div class="item">
                      <img class="icon" src="@/assets/icon/ic_blood_pressure.jpg" alt="blood pressure"/>
                      <div class="value">血压</div>
                    </div>
                    <div class="item">
                      <img class="icon" src="@/assets/icon/ic_blood_oxygen.jpg" alt="blood oxygen"/>
                      <div class="value">血氧饱和度</div>
                    </div>
                  </div>
                  <div class="line">
                    <div class="item">
                      <img class="icon" src="@/assets/icon/ic_sleep.jpg" alt="sleep"/>
                      <div class="value">睡眠</div>
                    </div>
                    <div class="item">
                      <img class="icon" src="@/assets/icon/ic_locate.jpg" alt="locate"/>
                      <div class="value">定位</div>
                    </div>
                    <div class="item">
                      <img class="icon" src="@/assets/icon/ic_step.jpg" alt="step"/>
                      <div class="value">计步</div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div class="bailout">
              <div class="subtitle">医疗救助</div>
              <div class="images">
                <img src="@/assets/service.png" class="image" alt="service"/>
                <img src="@/assets/service_2.png" class="image" alt="service2"/>
              </div>
              <div class="exception">
                <div class="menu">
                  <div class="title3">健康异常</div>
                  <div class="title3">跌倒</div>
                  <div class="title3">SOS</div>
                </div>
                <div class="description">平台将联动120为您的家人提供紧急救助服务及医疗服务</div>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="warn">
              <div class="subtitle">异常报警</div>
              <div>健康异常发出警报，您的家人可及时收到报警信息</div>
              <img src="@/assets/app.png" class="image" alt="app"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { smoothScrollTo } from '@/util/scroller.js'

export default {
  name: 'index',
  mounted () {
    if (this.$route.params.type === 'toService') {
      const dom = document.getElementById('service')
      smoothScrollTo(dom.offsetTop - 60)
    } else {
      smoothScrollTo(0)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .banner {
    width: calc(100% - 600px);
    display: flex;
    margin-top: 20px;

    .image {
      width: 100%;
      height: 800px;
      background: aqua;
      object-fit: cover;
    }
  }

  .center {
    width: calc(100% - 600px);
    display: flex;
    flex-direction: column;
    align-items: center;

    .data {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;

      .content {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        padding: 20px 0;

        .item {
          display: flex;
          align-items: center;
          flex-direction: column;

          .name {
            font-size: 20px;
          }

          .value {
            font-size: 24px;
            margin-top: 10px;

            .unit {
              font-size: 16px;
            }
          }
        }
      }

      .image {
        width: 100%;
        background: aqua;
        height: 600px;
      }

      .title {
        margin: 40px 0;
      }
    }

    .service {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;

      .title {
        margin: 40px 0;
      }

      .content {
        display: flex;
        justify-content: space-between;

        .left {
          width: 50%;
          display: flex;
          flex-direction: column;

          .monitor {
            width: 100%;
            display: flex;

            .image {
              width: 300px;
              height: 300px;
            }

            .right {
              width: 100%;
              margin: 20px 40px 20px 20px;

              .top {
                display: flex;
                align-items: flex-end;

                .subtitle {
                  margin-right: 10px;
                }
              }

              .bottom {
                width: 100%;
                display: flex;
                flex-direction: column;

                .line {
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                  margin-top: 20px;

                  .item {
                    display: flex;
                    align-items: center;
                    flex-direction: column;

                    .icon {
                      width: 60px;
                      height: 60px;
                      margin-bottom: 20px;
                    }
                  }
                }
              }
            }
          }

          .bailout {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .subtitle {
              margin: 20px 0;
            }

            .images {
              width: 100%;
              display: flex;

              .left {
                display: flex;
              }

              .image {
                width: 200px;
                height: 200px;
                background: aqua;
                margin-right: 20px;
                object-fit: cover;
              }
            }

            .exception {
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: flex-start;

              .menu {
                display: flex;
                margin: 10px 0;

                .title3 {
                  font-size: 20px;
                  margin: 0 40px 0 0;
                }
              }

              .description {
                line-height: 30px;
                text-align: start;
              }
            }
          }

        }

        .right {
          width: 50%;
          margin-left: 80px;

          .warn {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .image {
              width: calc(100% - 100px);
              margin-top: 20px;
            }

            .subtitle {
              margin: 20px 0;
            }
          }
        }
      }
    }
  }
}
</style>
